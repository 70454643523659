<template>
    <h6 class="my-2">{{$t('Prosjektet')}} '{{orgUnitName}}' {{$t('er nå opprettet! Du kan gå direkte til modulene ved å klikke nedenfor.')}}</h6>
    <div class="d-flex justify-content-center">
        <a type="button" role="button" class="btn btn-outline-primary flex-fill" target="_blank" :href="`/timephased-cost-progress?Context=${orgUnitId}`">{{$t('Kost')}}</a>
        <a type="button" role="button" class="btn btn-outline-primary ms-2 flex-fill" target="_blank" :href="`/nt/documentregister?Context=${orgUnitId}`">{{$t('Dokument')}}</a>
        <a type="button" role="button" class="btn btn-outline-primary ms-2 flex-fill" target="_blank" :href="`/nt/scope-items?Context=${orgUnitId}`">{{$t('Saker')}}</a>
        <a type="button" role="button" class="btn btn-outline-primary ms-2 flex-fill" target="_blank" :href="`/schedules?Context=${orgUnitId}`">{{$t('Plan')}}</a>
    </div>
</template>
<script setup>

    import { ref, onMounted, defineProps } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean,
    });

    const dsCreateTemplateWf = getOrCreateDataObject({
        id: "dsCreateTemplateWf",
        viewName: 'atbv_Scope_Items',
        maxRecords: 1,
        whereClause: `ID=${props.itemId}`,
        allowInsert: false,
        allowDelete: false,
        allowUpdate: false,
        loadRecents: false,
        distinctRows: false,
        fields:
             [
                {name: "ID", type: "number" },
                {name: "ExternalID", type: "string" },
                {name: "ExternalRef", type: "string" }
             ]
    }); 
    const orgUnitId = ref(0);
    const orgUnitName = ref('');
    onMounted(async()=>{
        await dsCreateTemplateWf.load();
        orgUnitId.value = dsCreateTemplateWf.data[0].ExternalID
        orgUnitName.value = dsCreateTemplateWf.data[0].ExternalRef
    })

</script>